type CFImageOptions = {
    width: number,
    height: number,
    format?: "auto" | "avif" | "webp" | "json",
    fit?: "scale-down" | "contain" | "cover" | "crop" | "pad"
};

export default function(url:string|null, options:CFImageOptions) {
    if(url !== null) {
        const parsed_url = new URL(url);

        let options_map:string = Object.entries(options).map((val) => {
            return `${val[0]}=${val[1]}`;
        }).join(',')

        return `${parsed_url.origin}/cdn-cgi/image/${options_map}${parsed_url.pathname}`;
    } else {
        return url;
    }
}